import React from "react";

function MessagesHeader({ msgSidebarOpen, setMsgSidebarOpen, conversation }) {
  return (
    <div className="sticky top-16">
      <div className="flex items-center justify-between bg-white border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
        {/* People */}
        <div className="flex items-center">
          {/* Close button */}
          <button
            className="md:hidden text-gray-400 hover:text-gray-500 mr-4"
            onClick={() => setMsgSidebarOpen(!msgSidebarOpen)}
            aria-controls="messages-sidebar"
            aria-expanded={msgSidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* People list */}
          <div className="flex">
            {/* <img
              className="rounded-full border-2 border-white box-content mr-3"
              src={conversation.influencer.avatar}
              width="32"
              height="32"
              alt="influencer avatar"
            /> */}
            <a
              className="block my-auto text-sm font-semibold text-gray-80"
              target="_blank"
              href={`https://channel.so/${conversation.influencer.username}`}
            >
              {conversation.option}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagesHeader;
