import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ChannelLogo from "../../../images/logos/channel.png";
import {
  CogIcon,
  ChatAltIcon,
  CashIcon,
  CollectionIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 2xl:!w-64 flex-shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between my-5 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink exact to="/" className="block">
            <img
              className="h-6 w-auto"
              src={ChannelLogo}
              alt="Channel"
              style={{ objectFit: "contain" }}
            />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* Orders */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/dashboard/orders" && "bg-gray-900"
                }`}
              >
                <NavLink
                  exact
                  to="/dashboard/orders"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/dashboard/orders" && "hover:text-gray-200"
                  }`}
                >
                  <div className="flex">
                    <CollectionIcon
                      className="h-6 w-6 text-gray-200 hover:text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-100 duration-200">
                      Orders
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Messages */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/dashboard/messages" && "bg-gray-900"
                }`}
              >
                <NavLink
                  exact
                  to="/dashboard/messages"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/dashboard/messages" && "hover:text-gray-200"
                  }`}
                >
                  <div className="flex">
                    <ChatAltIcon
                      className="h-6 w-6 text-gray-200 hover:text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-100 duration-200">
                      Messages
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Settings */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes("settings") && "bg-gray-900"
                }`}
              >
                <NavLink
                  exact
                  to="/settings"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("settings") && "hover:text-gray-200"
                  }`}
                >
                  <div className="flex">
                    <CogIcon
                      className="h-6 w-6 text-gray-200 hover:text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-100 duration-200">
                      Settings
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
