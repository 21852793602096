import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { withRouter } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import OrdersTable from "./OrdersTable";

var unsubscribe;
var currentUserListener;
var ordersListener;

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const orderId = params.get("id") || null;

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (!user.emailVerified) {
          props.history.push("/verify");
        }

        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", async (snapshot) => {
            setCurrentUser(snapshot.val());

            if (orderId) {
              const selectedOrderRef = await firebase
                .database()
                .ref("all-orders")
                .child(orderId)
                .once("value");
              setSelectedOrder(selectedOrderRef.val());
            }

            ordersListener = await firebase
              .database()
              .ref("sponsor-orders")
              .child(snapshot.val().uid)
              .orderByChild("createdAt")
              .on("value", (snapshot) => {
                var ordersArray = [];
                snapshot.forEach((order) => {
                  ordersArray.push(order.val());
                });
                setOrders(ordersArray.reverse());
              });

            setPageLoading(false);
          });
      } else {
        props.history.push("/signin");
      }
    });

    return () => {
      if (currentUser) {
        firebase
          .database()
          .ref("users")
          .child(currentUser.uid)
          .off("value", currentUserListener);

        firebase
          .database()
          .ref("spnsor-orders")
          .child(currentUser.uid)
          .off("value", ordersListener);
      }

      unsubscribe();
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {pageLoading || !currentUser ? (
          <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen ">
            <div className="mt-2 text-center">
              <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
            </div>
          </div>
        ) : (
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    Orders 💸
                  </h1>
                </div>
              </div>

              {/* Table */}
              <OrdersTable
                user={currentUser}
                orders={orders}
                selectedOrder={selectedOrder}
              />
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default withRouter(Orders);
