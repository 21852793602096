import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "firebase/app";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyCvyfAuUGk4v7G6ZLzzFw3srKV_JXwNdxo",
  authDomain: "channelso.firebaseapp.com",
  databaseURL: "https://channelso.firebaseio.com",
  projectId: "channelso",
  storageBucket: "channelso.appspot.com",
  messagingSenderId: "725082708086",
  appId: "1:725082708086:web:66cadfa648a0af7506e757",
  measurementId: "G-M0SKKRJB40",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
