import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";

var conversationsListener;

function DirectMessages({
  user,
  setMsgSidebarOpen,
  selectedConversation,
  setSelectedConversation,
}) {
  const [conversations, setConversations] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selectedConversation) {
      setSelected(selectedConversation.id);
    }
  }, [selectedConversation]);

  useEffect(() => {
    conversationsListener = firebase
      .database()
      .ref("sponsor-conversations")
      .child(user.uid)
      .orderByChild("unreadFromInfluencer")
      .on("value", (snapshot) => {
        var conversationsArray = [];
        snapshot.forEach((conversation) => {
          conversationsArray.push(conversation.val());
        });
        setConversations(conversationsArray.reverse());
      });

    return () => {
      firebase
        .database()
        .ref("sponsor-conversations")
        .child(user.uid)
        .off("value", conversationsListener);
    };
  }, []);

  const onSelectConversation = (conversation) => {
    setSelected(conversation.id);
    setSelectedConversation(conversation);

    // set unreadFromInfluencer to 0 in sponsor-conversations
    firebase
      .database()
      .ref("sponsor-conversations")
      .child(`${user.uid}/${conversation.id}`)
      .update({ unreadFromInfluencer: null });
  };

  return (
    <div className="mt-4">
      <div className="text-xs font-semibold text-gray-400 uppercase mb-3">
        All messages
      </div>
      <ul className="mb-6">
        {conversations.map((conversation) => {
          return (
            <li
              key={conversation.id}
              className="-mx-2"
              onClick={() => {
                onSelectConversation(conversation);
              }}
            >
              <button
                className={`flex items-center justify-between w-full p-2 rounded ${
                  selected === conversation.id && "bg-indigo-100"
                }`}
                onClick={() => setMsgSidebarOpen(false)}
              >
                <div className="flex items-center">
                  <img
                    className="w-8 h-8 rounded-full mr-2"
                    src={conversation.influencer.avatar}
                    width="32"
                    height="32"
                    alt="influencer avatar"
                  />
                  <div className="truncate">
                    <span className="text-sm font-medium text-gray-800">
                      {conversation.influencer.name}
                    </span>
                  </div>
                </div>

                {conversation.unreadFromInfluencer &&
                  conversation.unreadFromInfluencer > 0 && (
                    <div className="flex items-center ml-2">
                      <div className="text-xs inline-flex font-medium bg-indigo-400 text-white rounded-full text-center leading-5 px-2">
                        {conversation.unreadFromInfluencer}
                      </div>
                    </div>
                  )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DirectMessages;
