import React from "react";
import { Popup } from "react-typeform-embed";
import { CheckCircleIcon } from "@heroicons/react/solid";

const includedFeatures = [
  "Offer one-time sponsorships",
  "Offer long-term sponsorships",
  "Spammy message protection",
  "Manage all sponsors in one place",
];

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto">
        <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-100" />
            <div className="relative max-w-7xl mx-auto">
              <div className="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="flex-1 bg-gray-100 px-6 py-8 lg:p-12">
                  <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                    Get sponsored via Channel 💸
                  </h3>
                  <p className="mt-6 text-base text-gray-500">
                    We make it easy for you to get sponsorships and monetize
                    from your creative works!
                  </p>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                        What's included
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul
                      role="list"
                      className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                    >
                      {includedFeatures.map((feature) => (
                        <li
                          key={feature}
                          className="flex items-start lg:col-span-1"
                        >
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="py-8 px-6 text-center bg-indigo-100 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    You get sponsored, then we get paid!
                  </p>
                  <div className="mt-4 flex items-center justify-center text-gray-900">
                    <span className="text-5xl font-extrabold">10%</span>
                    <div className="ml-2 text-sm">from your revenue</div>
                  </div>

                  <div className="ml-2 text-sm">
                    Payment processing fees included!
                  </div>

                  <div className="mt-6">
                    <div className="rounded-md">
                      <a
                        href="/signup"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                      >
                        👉 Get started for free
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
