import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import SingleOrderSlide from "./SingleOrderSlide";
import SingleOrder from "./OrdersTableItem";

function OrdersTable({ user, orders, selectedOrder }) {
  const [order, setOrder] = useState(null);
  const [openSingleOrderSlide, setOpenSingleOrderSlide] = useState(false);

  useEffect(() => {
    focusHandling("outline");
  }, [orders]);

  useEffect(() => {
    if (selectedOrder) {
      setOrder(selectedOrder);
      setOpenSingleOrderSlide(true);
    }
  }, [selectedOrder]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <SingleOrderSlide
        user={user}
        order={order}
        openSingleOrderSlide={openSingleOrderSlide}
        setOpenSingleOrderSlide={setOpenSingleOrderSlide}
      />
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          All Orders{" "}
          <span className="text-gray-400 font-medium">{orders.length}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-gray-200">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Order</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Influencer</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Duration</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Total</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  Unread messages
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {orders.map((order) => {
              return (
                <SingleOrder
                  key={order.id}
                  user={user}
                  order={order}
                  setOrder={setOrder}
                  openSingleOrderSlide={openSingleOrderSlide}
                  setOpenSingleOrderSlide={setOpenSingleOrderSlide}
                />
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrdersTable;
