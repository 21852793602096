import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition.js";

function FeaturesHome() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-5xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4" data-aos="fade-up">
              Getting sponsored couldn't be easier
            </h1>
          </div>

          {/* Items */}
          <div className="grid gap-20 sm:gap-30">
            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <img
                  loading="lazy"
                  className="w-full mx-auto md:max-w-none h-auto rounded-lg"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/assets%2Fone-time.png?alt=media&token=315592e6-1831-432e-89cb-bdc91d6fc7f0"
                  }
                  width="500"
                  alt="One-time sponsorships"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Sell one-time sponsorships</h3>
                  <p className="text-xl text-gray-600 mb-4">
                    <span className="bg-yellow-100">
                      No more guess work for sponsors.
                    </span>{" "}
                    List what you can offer and name your price, the right
                    sponsors will come to you and make the payment within
                    minutes.
                  </p>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  loading="lazy"
                  className="w-full mx-auto md:max-w-none h-auto rounded-lg border border-gray-200"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/assets%2Flong-term-partnership.gif?alt=media&token=ca6bdba0-aa2c-42b2-9f1c-d036273cb967"
                  }
                  width="500"
                  alt="Subscription sponsorships"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <h3 className="h3 mb-3">Offer long-term partnerships</h3>
                  <p className="text-xl text-gray-600 mb-4">
                    Develop long-term relationships with your sponsors.{" "}
                    <span className="bg-yellow-100">
                      Get a stable income for a period of time.
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <img
                  loading="lazy"
                  className="w-full mx-auto md:max-w-none h-auto rounded-lg border border-gray-200"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/assets%2Fmessage-paywall.png?alt=media&token=febe47bb-7b63-4cac-b8bf-584ae5c2d61c"
                  }
                  width="500"
                  alt="Stop spams"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5"
                data-aos="fade-left"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Stop spammy messages</h3>
                  <p className="text-xl text-gray-600 mb-4">
                    No one likes being spammed. Anyone who wants to send you a
                    message needs to pay a one-time-payment of $2.99 to get
                    access of sending messages.{" "}
                    <span className="bg-yellow-100">
                      Trust us, a little paywall will help stop 99% of spam
                      messages!
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* 4th item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  loading="lazy"
                  className="w-full mx-auto md:max-w-none h-auto rounded-lg border border-gray-200"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/assets%2Fdashboard.png?alt=media&token=ccd372df-a790-4747-a7fa-c84ec8467a6c"
                  }
                  width="500"
                  alt="Manage everything in one place"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <h3 className="h3 mb-3">Manage everything in one place</h3>
                  <p className="text-xl text-gray-600 mb-4">
                    <span className="bg-yellow-100">
                      Manage sponsorship options and communications all in one
                      place
                    </span>
                    , easy for you to stay on top of it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
