import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { Link, withRouter } from "react-router-dom";

function Sponsorships(props) {
  const { allOptions, username } = props;

  if (allOptions.length === 0) {
    return (
      <div className="max-w-5xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
        <h2 className="flex justify-center text-2xl font-extrabold text-gray-800 sm:text-3xl sm:leading-none sm:tracking-tight">
          No avaiable sponsorships
        </h2>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="flex justify-center text-2xl font-extrabold text-gray-800 sm:text-3xl sm:leading-none sm:tracking-tight">
        Select a sponsorship option
      </h2>

      <div
        className={`w-full md:gap-8 mt-12 ${
          allOptions.length < 2
            ? "flex justify-center"
            : allOptions.length === 2
            ? "grid md:grid-cols-2 gap-8"
            : allOptions.length > 2
            ? "grid md:grid-cols-3 gap-8"
            : ""
        }`}
      >
        {allOptions.map((option, index) => (
          <div
            key={index}
            className="relative p-6 bg-white border border-gray-200 rounded-2xl flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-800">
                {option.name}
              </h3>
              {option.type === "long-term" ? (
                <p className="absolute top-0 py-1.5 px-4 bg-indigo-100 rounded-full text-xs font-semibold uppercase tracking-wide text-indigo-600 transform -translate-y-1/2">
                  Long-term
                </p>
              ) : (
                <p className="absolute top-0 py-1.5 px-4 bg-yellow-100 rounded-full text-xs font-semibold uppercase tracking-wide text-yellow-600 transform -translate-y-1/2">
                  One-time
                </p>
              )}
              <p className="mt-4 flex items-baseline text-gray-800">
                <span className="text-2xl sm:text-3xl font-extrabold tracking-tight">
                  ${`${option.price}`}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {option.type === "long-term" &&
                    (option.duration > 1
                      ? ` for ${option.duration} months`
                      : " for 1 month")}
                </span>
              </p>

              {/* Feature list */}
              <ul role="list" className="mt-4 space-y-2">
                {option.keypoints.map((point, index) => (
                  <li key={index} className="flex">
                    <CheckIcon
                      className="flex-shrink-0 w-6 h-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-600">{point.label}</span>
                  </li>
                ))}
              </ul>
            </div>

            <Link
              className={
                "bg-indigo-50 text-indigo-700 hover:bg-indigo-100 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
              }
              to={`/${username}/${option.id}`}
            >
              👉 Interested in this one!
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withRouter(Sponsorships);
