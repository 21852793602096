import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./css/style.scss";

import AOS from "aos";
import Sticky from "sticky-js";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
import Influencer from "./pages/Influencer/Influencer";
import Message from "./pages/Message/Message";
import VerifyPending from "./pages/Dashboard/VerifyPending";
import DashboardOrders from "./pages/Dashboard/Orders/Orders";
import DashboardMessages from "./pages/Dashboard/Messages/Messages";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import PageNotFound from "./pages/PageNotFound";
import SettingsAccount from "./pages/Dashboard/Settings/Account";
import SingleSponsorship from "./pages/Influencer/SingleSponsorship";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky("[data-sticky]");
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/dashboard/orders">
          <DashboardOrders />
        </Route>
        <Route exact path="/dashboard/messages">
          <DashboardMessages />
        </Route>
        <Route exact path="/settings">
          <SettingsAccount />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/verify">
          <VerifyPending />
        </Route>
        <Route path="/404">
          <PageNotFound />
        </Route>
        <Route exact path="/message/:conversationId">
          <Message />
        </Route>
        <Route exact path="/:username">
          <Influencer />
        </Route>
        <Route exact path="/:username/:optionId">
          <SingleSponsorship />
        </Route>
      </Switch>
    </>
  );
}

export default App;
