import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { MailIcon } from "@heroicons/react/solid";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardForm from "../../components/Stripe/StripeForm";
import ModalBasic from "../../components/ModalBasic";
import { withRouter } from "react-router-dom";
import HlsPlayer from "../../utils/VideoPlayer/HlsPlayer";

function ProfileHeader(props) {
  const { influencer, currentUser, allOptions } = props;
  const [optionInterested, setOptionInterested] = useState(
    allOptions.length > 0
      ? `${allOptions[0].name} - $${allOptions[0].price} ${
          allOptions[0].type === "long-term"
            ? `for ${
                parseInt(allOptions[0].duration) > 1
                  ? parseInt(allOptions[0].duration) + " months"
                  : "1 month"
              }`
            : "one-time"
        }`
      : null
  );
  const [message, setMessage] = useState("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  if (influencer.videoMessage) {
    var gifPoster =
      "https://image.mux.com/" +
      influencer.videoMessage.playbackIds[0].id +
      "/animated.gif";
    var stillPoster =
      "https://image.mux.com/" +
      influencer.videoMessage.playbackIds[0].id +
      "/thumbnail.jpg?width=400";
    var videoStreamingURL =
      "https://stream.mux.com/" +
      influencer.videoMessage.playbackIds[0].id +
      ".m3u8";
  }

  const onValidateAndSendMessage = () => {
    if (!currentUser) {
      // user not logged in
      setAuthModalOpen(true);
    } else {
      if (currentUser.messageOK) {
        setSendMessageModalOpen(true);
      } else {
        setPaywallModalOpen(true);
      }
    }
  };

  const handleStripeSubmit = async () => {
    setIsPaymentProcessing(true);
    setStripeError(null);

    const unlockMessagingPaymentIntent = firebase
      .functions()
      .httpsCallable("stripe-unlockMessagingPaymentIntent");

    const paymentIntent = await unlockMessagingPaymentIntent({
      unlockMessageFee: 299, // in cents
    });

    const clientSecret = paymentIntent.data.client_secret;

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (paymentResult.error) {
      // failed payment
      setStripeError(paymentResult.error.message);
    } else {
      // successful payment
      // set messageOK to true for currentUser
      await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .update({ messageOK: true });

      toast.dark("🥳 Payment processed! Now you can send messages!", {
        autoClose: 4000,
      });

      setPaywallModalOpen(false);
      setSendMessageModalOpen(true);
    }

    setIsPaymentProcessing(false);
  };

  const onSendMessage = async () => {
    setBtnLoading(true);
    const conversationId = nanoid(20);

    // add message to all-conversations
    await firebase
      .database()
      .ref("all-conversations")
      .child(conversationId)
      .set({
        id: conversationId,
        influencer: influencer,
        sponsor: currentUser,
        option: optionInterested,
      });
    await firebase
      .database()
      .ref("all-conversations")
      .child(`${conversationId}/messages`)
      .push({
        sendBy: {
          avatar: currentUser.avatar,
          name: currentUser.name,
          uid: currentUser.uid,
          email: currentUser.email,
          role: "sponsor",
        },
        message: message,
        createdAt: Date.now(),
      });

    // add conversationId to influencer-conversations
    await firebase
      .database()
      .ref("influencer-conversations")
      .child(`${influencer.uid}/${conversationId}`)
      .set({
        unreadFromSponsor: 1,
        id: conversationId,
        sponsor: currentUser,
        option: optionInterested,
        createdAt: Date.now(),
      });

    // add conversationId to sponsor-conversations
    await firebase
      .database()
      .ref("sponsor-conversations")
      .child(`${currentUser.uid}/${conversationId}`)
      .set({
        id: conversationId,
        influencer: influencer,
        option: optionInterested,
        createdAt: Date.now(),
      });

    toast.dark("✅ Your message is sent successfully!", {
      autoClose: 4000,
    });
    setMessage("");
    setBtnLoading(false);
    setSendMessageModalOpen(false);
  };

  return (
    <div className="relative flex overflow-hidden bg-white pt-20">
      {/* Auth modal */}
      <ModalBasic
        id="message-paywall-modal"
        modalOpen={authModalOpen}
        setModalOpen={setAuthModalOpen}
        title={"Please log in first"}
      >
        {/* Modal content */}
        <div className="relative md:flex">
          <div className="w-full">
            <div className="px-5 py-4">
              <p className="text-sm text-gray-600">
                You have to log in first in order to send messages.
              </p>
              <p className="text-sm text-gray-600 mt-2">
                Secondly, you need to pay a one-time-payment of $2.99 to unlock
                the message access. We set up this little paywall primarily to
                prevent our creators from being spammed. 🙏
              </p>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => props.history.push("/signin?username=damon")}
            >
              Log in
            </button>
          </div>
        </div>
      </ModalBasic>

      {/* Video modal */}
      <Transition.Root show={openVideoModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={setOpenVideoModal}
        >
          <div className="flex items-end justify-center mt-12 h-1/2 text-center sm:block">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full ">
                <HlsPlayer
                  src={videoStreamingURL}
                  styling="w-full rounded"
                  loop={false}
                  poster={stillPoster}
                  controls={true}
                  autoPlay={true}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Send new message */}
      <ModalBasic
        id="send-message-modal"
        modalOpen={sendMessageModalOpen}
        setModalOpen={setSendMessageModalOpen}
        title={`Send a message to ${influencer.name.split(" ")[0]}`}
      >
        {/* Modal content */}
        <div className="relative md:flex">
          <div className="w-full">
            <div className="px-5 py-4">
              <div className="space-y-3">
                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="name"
                  >
                    Which sponsorship are you interested in?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    id="optionInterested"
                    className="form-select w-full px-2 py-1"
                    value={optionInterested}
                    onChange={(e) => setOptionInterested(e.target.value)}
                  >
                    {allOptions.map((option, index) => {
                      const optionName = `${option.name} - $${option.price} ${
                        option.type === "long-term"
                          ? `for ${
                              option.duration > 1
                                ? option.duration + " months"
                                : "1 month"
                            }`
                          : "one-time"
                      }`;
                      return (
                        <option value={optionName} key={index}>
                          {optionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="message"
                  >
                    Your message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="message"
                    className="form-textarea w-full px-2 py-1"
                    rows="7"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setSendMessageModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={onSendMessage}
              disabled={btnLoading}
            >
              {btnLoading ? (
                <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </ModalBasic>

      {/* Message paywall */}
      <ModalBasic
        id="message-paywall-modal"
        modalOpen={paywallModalOpen}
        setModalOpen={setPaywallModalOpen}
        title={"Ready to send a message?"}
      >
        {/* Modal content */}
        <div className="relative md:flex">
          <div className="w-full">
            <div className="px-5 py-4">
              <div className="space-y-3">
                <div className="rounded-md bg-indigo-600 text-white p-3">
                  <p className="font-semibold">
                    ✨ One-time-payment:{" "}
                    <span className="line-through text-gray-300">$9.99</span>{" "}
                    $2.99
                  </p>
                </div>
                <div>
                  <ul className="text-sm space-y-2 mb-5 text-gray-600">
                    <li>
                      ✅{" "}
                      <span className="font-semibold text-gray-800">
                        Billed once, never again!
                      </span>{" "}
                      It gives you the access to send messages to any creators
                      in the future.
                    </li>
                    <li>
                      ✅{" "}
                      <span className="font-semibold text-gray-800">
                        Stop spams!
                      </span>{" "}
                      People don't like being spammed. A little paywall will
                      help stop 99% of spam messages!
                    </li>
                  </ul>
                </div>
                <div>
                  <CardForm />
                  {stripeError && (
                    <p className="mt-1 text-sm text-red-500">{stripeError}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={handleStripeSubmit}
              disabled={isPaymentProcessing}
            >
              {isPaymentProcessing ? (
                <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
              ) : (
                "Pay to unlock"
              )}
            </button>
          </div>
        </div>
      </ModalBasic>

      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {/* Profile header */}
            <div>
              <div>
                <img
                  className="h-32 w-full object-cover lg:h-64"
                  src={
                    influencer.cover ||
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/cover-photo%2FCtAUbgNxSxUGDulAMf8QbFbisDq1%2Fphoto-1506744038136-46273834b3fb.jpeg?alt=media&token=1d738d9b-04d6-4568-8ecf-1cdcdd629e29"
                  }
                  alt="influencer avatar"
                />
              </div>
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="-mt-12 sm:-mt-16">
                  <div className="flex justify-center">
                    {influencer.videoMessage ? (
                      <img
                        className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover cursor-pointer"
                        src={gifPoster}
                        alt="profile gif"
                        onClick={() => setOpenVideoModal(true)}
                      />
                    ) : (
                      <img
                        className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover"
                        src={
                          influencer.avatar ||
                          `https://ui-avatars.com/api/?background=6265f0&color=fff&size=256&font-size=0.5&bold=true&name=${influencer.name}`
                        }
                        alt="profile photo"
                      />
                    )}
                  </div>
                  <div className="mt-4 flex justify-center">
                    <h1 className="text-lg sm:text-2xl font-bold text-gray-800">
                      {influencer.name}
                    </h1>
                  </div>

                  {/* <div className="flex justify-center">
                    {influencer.sponsorCount > 0 && (
                      <h1 className="text-md sm:text-base font-bold text-gray-800">
                        🙌 {influencer.sponsorCount}{" "}
                        {`sponsor${influencer.sponsorCount > 1 ? "s" : ""}`}
                      </h1>
                    )}
                  </div> */}

                  {allOptions.length > 0 && (
                    <div className="mt-4 flex flex-row justify-center">
                      <span className="mr-3 my-auto text-md sm:text-base font-bold text-gray-600">
                        Have questions?
                      </span>
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        onClick={onValidateAndSendMessage}
                      >
                        <MailIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-600"
                          aria-hidden="true"
                        />
                        <span>Message</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ProfileHeader);
