import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import FeaturesHome from "../partials/FeaturesHome";
import HomeWallOfLove from "../partials/HomeWallOfLove";
import HomePricing from "../partials/HomePricing";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";

function onAuthStateChange(callback) {
  return firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      await firebase
        .database()
        .ref("users")
        .child(user.uid)
        .on("value", (snapshot) => {
          callback(snapshot.val());
        });
    } else {
      callback(null);
    }
  });
}

function Home() {
  const [currentUser, setCurrentUser] = useState(null);

  // set currentUser
  useEffect(() => {
    const unsubscribe = onAuthStateChange(setCurrentUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        {/* <HomeWallOfLove /> */}
        <HomePricing />
        {/* <Cta /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
