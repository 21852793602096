import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { nanoid } from "nanoid";
import { Link, withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import parse from "html-react-parser";
import CardForm from "../../components/Stripe/StripeForm";
import ModalBasic from "../../components/ModalBasic";

function SingleSponsorshipDetails(props) {
  const { currentUser } = props;
  const [influencer, setInfluencer] = useState(null);
  const [option, setOption] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const optionId = props.match.params.optionId || null;
  const username = props.match.params.username || null;
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [sponsorshipOption, setSponsorshipOption] = useState(null);
  const [message, setMessage] = useState("");
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    firebase
      .database()
      .ref("usernames")
      .child(username)
      .once("value", (snapshot) => {
        if (!snapshot.exists()) {
          // no such username
          props.history.push("/404");
          return;
        } else {
          setInfluencer(snapshot.val());
          firebase
            .database()
            .ref("sponsorships")
            .child(`${snapshot.val().uid}/${optionId}`)
            .once("value", (snapshot) => {
              if (!snapshot.exists()) {
                props.history.push("/404");
                return;
              }
              setOption(snapshot.val());
              setPageLoading(false);
            });
        }
      });
  }, []);

  const handleStripeSubmit = async () => {
    if (!confirm) {
      toast.dark("Please confirm the checkbox!", {
        autoClose: 4000,
      });
      return;
    }

    setIsPaymentProcessing(true);
    setStripeError(null);

    const depositPaymentIntent = firebase
      .functions()
      .httpsCallable("stripe-depositPaymentIntent");
    // const depositPaymentIntent = firebase
    //   .functions()
    //   .httpsCallable("stripe-testDepositPaymentIntent");

    const paymentIntent = await depositPaymentIntent({
      price: sponsorshipOption.price, // in dollar
      customerId: currentUser.stripe.customerId,
    });

    const clientSecret = paymentIntent.data.client_secret;

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
      setup_future_usage: "off_session",
    });

    if (paymentResult.error) {
      // failed payment
      setStripeError(paymentResult.error.message);
      toast.dark("Oops, something wrong with your card 😰", {
        autoClose: 4000,
      });
    } else {
      const orderId = nanoid(20);

      // add order to all-orders
      await firebase
        .database()
        .ref("all-orders")
        .child(orderId)
        .set({
          id: orderId,
          influencer: influencer,
          sponsor: {
            name: currentUser.name,
            email: currentUser.email,
            avatar: currentUser.avatar || null,
            uid: currentUser.uid,
            stripe: currentUser.stripe,
          },
          sponsorship: {
            ...sponsorshipOption,
            description: null,
          },
          status: "pending",
          payment: { intentId: paymentIntent.data.id, via: "stripe" },
          createdAt: Date.now(),
        });

      await firebase
        .database()
        .ref("all-orders")
        .child(`${orderId}/messages`)
        .push({
          sendBy: {
            avatar: currentUser.avatar,
            name: currentUser.name,
            uid: currentUser.uid,
            email: currentUser.email,
            role: "sponsor",
          },
          message: message,
          createdAt: Date.now(),
        });

      // add order to influencer-orders
      await firebase
        .database()
        .ref("influencer-orders")
        .child(`${influencer.uid}/${orderId}`)
        .set({
          id: orderId,
          sponsor: {
            name: currentUser.name,
            email: currentUser.email,
            avatar: currentUser.avatar || null,
            uid: currentUser.uid,
            stripe: currentUser.stripe,
          },
          sponsorship: {
            ...sponsorshipOption,
            description: null,
          },
          status: "pending",
          payment: { intentId: paymentIntent.data.id, via: "stripe" },
          createdAt: Date.now(),
        });

      // add order to sponsor-orders
      await firebase
        .database()
        .ref("sponsor-orders")
        .child(`${currentUser.uid}/${orderId}`)
        .set({
          id: orderId,
          influencer: influencer,
          sponsorship: { ...sponsorshipOption, description: null },
          status: "pending",
          createdAt: Date.now(),
        });

      toast.dark(
        `🥳 Your request was submitted! Please wait for ${influencer.name} to get back to you!`,
        {
          autoClose: 4000,
        }
      );

      setPaymentModalOpen(false);
    }

    setMessage("");
    setIsPaymentProcessing(false);
  };

  const openPaymentModal = (option) => {
    if (!currentUser) {
      setAuthModalOpen(true);
      return;
    }

    setSponsorshipOption(option);
    setPaymentModalOpen(true);
  };

  if (pageLoading) {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 mt-12">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/* Auth modal */}
      <ModalBasic
        id="message-paywall-modal"
        modalOpen={authModalOpen}
        setModalOpen={setAuthModalOpen}
        title={"Please log in to continue"}
      >
        {/* Modal content */}
        <div className="relative md:flex">
          <div className="w-full">
            <div className="px-5 py-4">
              <p className="text-sm text-gray-600">
                You have to log in first in order to sponsor.
              </p>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => props.history.push("/signin?username=damon")}
            >
              Log in
            </button>
          </div>
        </div>
      </ModalBasic>

      {/* Sponsor now modal */}
      <ModalBasic
        id="sponsor-now-modal"
        modalOpen={paymentModalOpen}
        setModalOpen={setPaymentModalOpen}
        title={`Ready to sponsor ${influencer.name}?`}
      >
        {/* Modal content */}
        <div className="relative md:flex">
          <div className="w-full">
            <div className="px-5 py-4">
              <div className="space-y-3">
                {sponsorshipOption && (
                  <>
                    <div className="rounded-md bg-indigo-600 text-white p-3">
                      <p className="font-semibold">
                        ✨{" "}
                        {`${sponsorshipOption.name} - $${
                          sponsorshipOption.price
                        } ${
                          sponsorshipOption.type === "long-term"
                            ? `for ${
                                sponsorshipOption.duration > 1
                                  ? sponsorshipOption.duration + " months"
                                  : "1 month"
                              }`
                            : "one-time"
                        }`}
                      </p>
                    </div>
                    <div>
                      <div className="flex mb-5 text-gray-600">
                        <input
                          id="confirm"
                          name="confirm"
                          type="checkbox"
                          className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          checked={confirm}
                          onChange={(e) => setConfirm(e.target.checked)}
                        />
                        <label
                          htmlFor="confirm"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          {sponsorshipOption.price / 100 < 100 ? (
                            <span className="font-semibold text-gray-800 bg-yellow-100">
                              You'll be charged a non-refundable $1 platform fee
                              to submit your request!
                            </span>
                          ) : (
                            <span className="font-semibold text-gray-800 bg-yellow-100">
                              You'll be charged a non-refundable $
                              {sponsorshipOption.price / 100} platform fee (1%
                              of the total price) to submit your request!
                            </span>
                          )}{" "}
                          When {influencer.name.split(" ")[0]} approves your
                          request, you'll be charged the full price $
                          {sponsorshipOption.price}. However, if{" "}
                          {influencer.name.split(" ")[0]} thinks it not a good
                          fit, you won't be charged again, but the platform fee
                          $
                          {sponsorshipOption.price / 100 > 1
                            ? sponsorshipOption.price / 100
                            : 1}{" "}
                          can't be refunded.
                        </label>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="card"
                  >
                    Card information <span className="text-red-500">*</span>
                  </label>
                  <CardForm />
                  {stripeError && (
                    <p className="mt-1 text-sm text-red-500">{stripeError}</p>
                  )}
                </div>
                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="message"
                  >
                    Your message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="message"
                    className="form-textarea w-full px-2 py-1"
                    rows="7"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            {sponsorshipOption && (
              <button
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={handleStripeSubmit}
                disabled={isPaymentProcessing}
              >
                {isPaymentProcessing ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  `Submit your request`
                )}
              </button>
            )}
          </div>
        </div>
      </ModalBasic>

      {/*  Single sponsorship content */}
      <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 px-6 lg:px-0 py-16">
        {/* Content */}
        <div>
          <div className="mb-3">
            <Link
              className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
              to="."
            >
              &lt;- Back to all options
            </Link>
          </div>
          <header className="mb-4">
            {/* Title */}
            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-2">
              {option.name}
            </h1>
            <div className="sponsorship-description">
              {parse(option.description)}
            </div>
          </header>
        </div>

        {/* Sidebar */}
        <div>
          <div className="bg-white p-5 shadow-lg rounded-sm border border-gray-200 lg:w-72 xl:w-80">
            <div className="flex-grow">
              {/* Header */}
              <header className="mb-3">
                <h3 className="text-lg text-gray-800 font-semibold">
                  {option.name}
                </h3>
              </header>
              {/* Rating and price */}
              <div className="flex flex-wrap justify-between items-center mb-4">
                {/* Rating */}
                <div className="flex items-center space-x-2 mr-2">
                  {/* Rate */}
                  <div className="inline-flex text-base font-medium text-gray-600">
                    ${`${option.price}`}{" "}
                    {option.type === "long-term" &&
                      `for ${
                        option.duration > 1
                          ? `${option.duration} months`
                          : "1 month"
                      }`}
                  </div>
                </div>
                {/* Price */}
                <div>
                  <div
                    className={`inline-flex text-sm font-medium ${
                      option.type === "one-time"
                        ? "bg-yellow-100 text-yellow-600"
                        : "bg-indigo-100 text-indigo-600"
                    }  rounded-full text-center px-2 py-0.5`}
                  >
                    {option.type === "one-time" ? "One-time" : "Long-term"}
                  </div>
                </div>
              </div>

              {/* Features list */}
              <ul className="text-sm space-y-2 mb-5">
                {option.keypoints.map((point, index) => {
                  return (
                    <li className="flex items-center" key={index}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 fill-current text-green-400 flex-shrink-0 mr-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <div>{point.label}</div>
                    </li>
                  );
                })}

                {/* <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 fill-current text-green-400 flex-shrink-0 mr-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <div>
                    Deliver in {option.ETA} business{" "}
                    {option.ETA > 1 ? "days" : "day"}
                  </div>
                </li> */}
              </ul>
            </div>
            {/* Card footer */}
            <div>
              <button
                className="w-full btn bg-indigo-600 text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  openPaymentModal(option);
                }}
              >
                Sponsor now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SingleSponsorshipDetails);
