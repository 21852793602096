import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";

function MessagesFooter({ user, conversation }) {
  const [newMessage, setNewMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const onSendMessage = async () => {
    if (newMessage === "") return;

    setBtnLoading(true);

    // add message to all-conversations
    await firebase
      .database()
      .ref("all-conversations")
      .child(`${conversation.id}/messages`)
      .push({
        sendBy: {
          avatar: user.avatar,
          name: user.name,
          uid: user.uid,
          email: user.email,
          role: "sponsor",
        },
        message: newMessage,
        createdAt: Date.now(),
      });

    // increment unreadFromSponsor in influencer-conversations by 1
    const unreadCount = await firebase
      .database()
      .ref("influencer-conversations")
      .child(
        `${conversation.influencer.uid}/${conversation.id}/unreadFromSponsor`
      );
    unreadCount.transaction(function (count) {
      return (count || 0) + 1;
    });

    setNewMessage("");
    setBtnLoading(false);
  };

  return (
    <div className="sticky bottom-0">
      <div className="flex items-center justify-between bg-white border-t border-gray-200 px-4 sm:px-6 md:px-5 h-16">
        {/* Message input */}
        <div className="flex-grow flex">
          <div className="flex-grow mr-3">
            <label htmlFor="message-input" className="sr-only">
              Type a message
            </label>
            <input
              id="newMessage"
              name="newMessage"
              className="form-input w-full bg-gray-100 border-transparent focus:bg-white focus:border-gray-300"
              type="text"
              placeholder="Type your message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
          </div>
          <button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
            onClick={onSendMessage}
            disabled={btnLoading}
          >
            {btnLoading ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessagesFooter;
