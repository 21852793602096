import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import ProfileHeader from "./ProfileHeader";
import Sponsorships from "./Sponsorships";

const stripeMessagePaywall = loadStripe(
  "pk_live_51JeZ3PFDa1YyQnpUtAplHrlcYMcl85cXrprx4E1s9vJOCq5qMUDFsWJdjl06zP3nGU5A0COHtWKxfviSyhu5mDbh00q5Rz5e4R"
);

var unsubscribe;
var currentUserListener;

function Influencer(props) {
  const [currentUser, setCurrentUser] = useState(null);
  const [influencer, setInfluencer] = useState(null);
  const [allOptions, setAllOptions] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const username = props.match.params.username || "";

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            setCurrentUser(snapshot.val());
          });
      }
    });

    return () => {
      if (currentUser) {
        firebase
          .database()
          .ref("users")
          .child(currentUser.uid)
          .off("value", currentUserListener);
      }

      unsubscribe();
    };
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref("usernames")
      .child(username)
      .once("value", (snapshot) => {
        if (!snapshot.exists()) {
          // no such username
          props.history.push("/404");
        } else {
          setInfluencer(snapshot.val());
          firebase
            .database()
            .ref("sponsorships")
            .child(snapshot.val().uid)
            .once("value", async (options) => {
              var optionsArray = [];
              options.forEach((option) => {
                optionsArray.push(option.val());
              });
              setAllOptions(optionsArray.reverse());
              setPageLoading(false);
            });
        }
      });
  }, []);

  if (pageLoading) {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 mt-12">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <Elements stripe={stripeMessagePaywall}>
          <ProfileHeader
            influencer={influencer}
            currentUser={currentUser}
            allOptions={allOptions}
          />
        </Elements>

        <Sponsorships
          username={username}
          influencer={influencer}
          currentUser={currentUser}
          allOptions={allOptions}
        />

        {/* <About /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default withRouter(Influencer);
