import React from "react";
import DirectMessages from "./DirectMessages";

function MessagesSidebar({
  user,
  msgSidebarOpen,
  setMsgSidebarOpen,
  selectedConversation,
  setSelectedConversation,
}) {
  return (
    <div
      id="messages-sidebar"
      className={`absolute z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transform transition-transform duration-200 ease-in-out ${
        msgSidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="sticky top-16 bg-white overflow-x-hidden overflow-y-auto no-scrollbar flex-shrink-0 border-r border-gray-200 md:w-72  h-[calc(100vh-64px)]">
        {/* #Marketing group */}
        <div>
          {/* Group body */}
          <div className="px-5 py-4">
            {/* Direct messages */}
            <DirectMessages
              user={user}
              msgSidebarOpen={msgSidebarOpen}
              setMsgSidebarOpen={setMsgSidebarOpen}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagesSidebar;
