import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { withRouter } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import AccountPanel from "./AccountPanel";

var unsubscribe;
var currentUserListener;

function Account(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", async (snapshot) => {
            setCurrentUser(snapshot.val());
            setPageLoading(false);
          });
      } else {
        props.history.push("/signin");
      }
    });

    return () => {
      if (currentUser) {
        firebase
          .database()
          .ref("users")
          .child(currentUser.uid)
          .off("value", currentUserListener);
      }

      unsubscribe();
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                Settings ⚙️
              </h1>
            </div>

            {/* Content */}
            <div className="bg-white border border-gray-200 rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                {pageLoading || !currentUser ? (
                  <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24">
                    <div className="mt-2 text-center">
                      <PulseLoader
                        sizeUnit={"px"}
                        size={15}
                        color={"#5850ec"}
                      />
                    </div>
                  </div>
                ) : (
                  <AccountPanel user={currentUser} />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default withRouter(Account);
