import React, { useState } from "react";
import IframeResizer from "iframe-resizer-react";

function HomeWallOfLove() {
  // Handle light modes
  const [darkMode, setDarkMode] = useState(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
        {/* Hero content */}
        <div className="py-12 md:py-20 dark:border-t dark:border-gray-800">
          {/* Section header */}
          <div className="max-w-4xl mx-auto text-center pb-6">
            <h1 className="h1 mb-4" data-aos="fade-up">
              Loved by creators <span className="text-red-600">❤️</span>
            </h1>
          </div>
          <IframeResizer
            log={false}
            src={"https://embed.testimonial.to/w/embed-so"}
            style={{ width: "1px", minWidth: "100%" }}
          />
        </div>
      </div>
    </section>
  );
}

export default HomeWallOfLove;
