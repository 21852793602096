import React, { useState } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";

const attachments = [
  { name: "resume_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
];

export default function ProfileHeader(props) {
  const { user, allOptions } = props;
  const [optionInterested, setOptionInterested] = useState(null);
  const [message, setMessage] = useState("");
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);

  return (
    <div className="max-w-5xl mx-auto py-16 px-4 sm:px-6 lg:px-8 pt-40">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-full"
              src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
              alt=""
            />
            <span
              className="absolute inset-0 shadow-inner rounded-full"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Ricardo Cooper</h1>
          <a
            href="https://channel.so/ricardo"
            className="text-sm font-semibold text-indigo-500"
            target="_blank"
          >
            channel.so/ricardo
          </a>
        </div>
      </div>
    </div>
  );
}
