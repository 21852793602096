import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import { formatDistance } from "date-fns";

var messagesListener;

function MessagesBody({ user, conversation, contentArea }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    contentArea.current.scrollTop = 99999999;
    console.log("scroll", contentArea.current.scrollTop);
  }, [messages]); // automatically scroll the chat and make the most recent message visible

  useEffect(() => {
    messagesListener = firebase
      .database()
      .ref("all-conversations")
      .child(`${conversation.id}/messages`)
      .orderByChild("createdAt")
      .on("value", (snapshot) => {
        var messagesArray = [];
        snapshot.forEach((message) => {
          messagesArray.push(message.val());
        });
        setMessages(messagesArray);
      });

    return () => {
      firebase
        .database()
        .ref("all-conversations")
        .child(`${user.id}/messages`)
        .off("value", messagesListener);
    };
  }, [conversation]);

  return (
    <div className="flex-grow px-4 sm:px-6 md:px-5 py-6 bg-gray-100">
      {/* Chat msg */}
      {messages.length === 0 ? (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      ) : (
        messages.map((message, index) => {
          return (
            <div className="flex items-start mb-4 last:mb-0" key={index}>
              <img
                className="rounded-full mr-4"
                src={message.sendBy.avatar}
                width="40"
                height="40"
                alt="User 01"
              />
              <div>
                <div
                  className={`text-sm ${
                    message.sendBy.role === "influencer"
                      ? "bg-white text-gray-800"
                      : "bg-indigo-500 text-white"
                  }  p-3 rounded-lg rounded-tl-none border border-gray-200 shadow-md mb-1`}
                >
                  {message.message}
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xs text-gray-500 font-medium">
                    {formatDistance(message.createdAt, Date.now())} ago
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default MessagesBody;
