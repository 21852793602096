import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import Sidebar from "./partials/Sidebar";
import Header from "./partials/Header";

import PendingVerifyImage from "../../images/verify-illustration.svg";

var unsubscribe;

function VerifyPending(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (user.emailVerified) {
          props.history.push("/settings");
        }

        setCurrentUser(user);
      } else {
        props.history.push("/signin");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const resendVerification = async () => {
    setBtnLoading(true);

    await currentUser.sendEmailVerification();

    setIsSent(true);
    setBtnLoading(false);

    toast.dark("Email sent! Please check your inbox.", {
      autoClose: 4000,
    });
  };

  if (!currentUser) return null;

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img
                    src={PendingVerifyImage}
                    width="176"
                    height="176"
                    alt="verify pending image"
                  />
                </div>
                <div className="mb-6">
                  Please go to your email inbox to verify your email first. If
                  you couldn't find the email, please check the spam folder, or
                  click the button below to resend the verification email.
                </div>
                <button
                  className={`btn bg-indigo-500 hover:bg-indigo-600 text-white w-72`}
                  onClick={() => resendVerification()}
                  disabled={isSent}
                >
                  {btnLoading ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                  ) : isSent ? (
                    "Email sent"
                  ) : (
                    "Resend verification email"
                  )}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default VerifyPending;
