import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import MessageHeader from "./MessageHeader";
import MessageDetails from "./MessageDetails";

var unsubscribe;

function Message(props) {
  const [influencer, setInfluencer] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const conversationId = props.match.params.conversationId || "";

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firebase
          .database()
          .ref("sponsor-conversations")
          .child(`${conversationId}/participants/${user.uid}`)
          .once("value", (snapshot) => {
            if (!snapshot.exists()) {
              // not belong to this conversation
              props.history.push("/404");
            } else {
              firebase
                .database()
                .ref("sponsor-conversations")
                .child(`${conversationId}/influencer`)
                .once("value", (snapshot) => {
                  setInfluencer(snapshot.val());

                  firebase
                    .database()
                    .ref("messages")
                    .child(`${conversationId}/messages`)
                    .once("value", async (messages) => {
                      var messagesArray = [];
                      messages.forEach((message) => {
                        messagesArray.push(message.val());
                      });
                      setMessages(messagesArray.reverse());
                      setPageLoading(false);
                    });
                });
            }
          });
      } else {
        props.history.push("/signin");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (pageLoading) {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 mt-12">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow bg-gray-50">
        {/*  Page sections */}
        <MessageHeader influencer={influencer} />
        <MessageDetails messages={messages} />
        {/* <About /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default withRouter(Message);
