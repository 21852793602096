import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { withRouter } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import MessagesSidebar from "./partials/MessagesSidebar";
import MessagesHeader from "./partials/MessagesHeader";
import MessagesBody from "./partials/MessagesBody";
import MessagesFooter from "./partials/MessagesFooter";
import messagePhoto from "../../../images/message-photo.svg";

var unsubscribe;
var currentUserListener;

function Orders(props) {
  const contentArea = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [msgSidebarOpen, setMsgSidebarOpen] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const conversationId = params.get("id") || null;

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (!user.emailVerified) {
          props.history.push("/verify");
        }

        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", async (snapshot) => {
            setCurrentUser(snapshot.val());

            if (conversationId) {
              const conversationRef = await firebase
                .database()
                .ref("all-conversations")
                .child(conversationId)
                .once("value");
              setSelectedConversation(conversationRef.val());
            }

            setPageLoading(false);
          });
      } else {
        props.history.push("/signin");
      }
    });

    return () => {
      if (currentUser) {
        firebase
          .database()
          .ref("users")
          .child(currentUser.uid)
          .off("value", currentUserListener);
      }

      unsubscribe();
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        ref={contentArea}
      >
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {pageLoading || !currentUser ? (
          <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen ">
            <div className="mt-2 text-center">
              <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
            </div>
          </div>
        ) : (
          <main>
            <div className="relative flex">
              {/* Messages sidebar */}
              <MessagesSidebar
                user={currentUser}
                msgSidebarOpen={msgSidebarOpen}
                setMsgSidebarOpen={setMsgSidebarOpen}
                selectedConversation={selectedConversation}
                setSelectedConversation={setSelectedConversation}
              />

              {/* Messages body */}

              <div
                className={`flex-grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out ${
                  msgSidebarOpen ? "translate-x-1/3" : "translate-x-0"
                }`}
              >
                {!selectedConversation ? (
                  <div className="flex flex-col mx-auto">
                    <img
                      className="h-auto"
                      src={messagePhoto}
                      style={{ width: "32rem", paddingTop: "12rem" }}
                    />
                    <p className="mx-auto text-2xl font-semibold text-gray-600 pt-12">
                      All your messages
                    </p>
                  </div>
                ) : (
                  <>
                    <MessagesHeader
                      msgSidebarOpen={msgSidebarOpen}
                      setMsgSidebarOpen={setMsgSidebarOpen}
                      conversation={selectedConversation}
                    />
                    <MessagesBody
                      user={currentUser}
                      conversation={selectedConversation}
                      contentArea={contentArea}
                    />
                    <MessagesFooter
                      user={currentUser}
                      conversation={selectedConversation}
                    />
                  </>
                )}
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default withRouter(Orders);
