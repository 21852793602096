import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

export default function HlsPlayer(props) {
  const videoRef = useRef(null);
  const src = props.src;

  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  return (
    <video
      disablePictureInPicture={true}
      controls={props.controls || false}
      autoPlay={true}
      playsInline
      id={props.videoId}
      poster={props.poster}
      ref={videoRef}
      loop={false}
      style={{
        width: "100%",
        height: "100%",
      }}
      className={props.styling}
      preload={props.preload || "auto"}
    />
  );
}
