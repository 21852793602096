import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import PulseLoader from "react-spinners/PulseLoader";
import ChannelLogo from "../images/logos/channel.png";
import GoogleLogo from "../images/logos/google.png";
import FacebookLogo from "../images/logos/facebook.png";
import TwitterLogo from "../images/logos/twitter.png";
import Header from "../partials/Header";

const signupRoles = [
  {
    id: "sponsor",
    title: "Sponsor",
  },
  { id: "influencer", title: "Creator" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

var unsubscribe;

function SignUp(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const username = params.get("username");

  useEffect(() => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        props.history.push("/dashboard/orders");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const onSocialAuth = async (social) => {
    var provider;

    if (social === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    if (social === "twitter") {
      provider = new firebase.auth.TwitterAuthProvider();
    }
    if (social === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    }

    try {
      const response = await firebase.auth().signInWithPopup(provider);

      if (response.additionalUserInfo.isNewUser) {
        const user = response.user;
        var userAvatar = user.photoURL;

        if (social === "twitter") {
          userAvatar = user.photoURL.replace("_normal", ""); // get original size twitter avatar
        }

        const userObj = {
          avatar: userAvatar || "",
          email: user.email || "",
          uid: user.uid,
          name: user.name ? user.name : user.email.split("@")[0],
          signUpVia: social,
          createdAt: Date.now(),
        };

        await firebase.database().ref("users").child(user.uid).set(userObj);
      }
      props.history.push("/");
    } catch (error) {
      toast.dark("Something went wrong, please try again!", {
        autoClose: 4000,
      });
    }
  };

  const onSignup = async (e) => {
    e.preventDefault();

    setBtnLoading(true);

    try {
      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      if (response.additionalUserInfo.isNewUser) {
        const userUid = response.user.uid;
        const userObject = {
          name: name,
          email: email,
          uid: userUid,
          role: "sponsor",
          createdAt: Date.now(),
          signUpVia: "email",
          avatar: `https://ui-avatars.com/api/?background=6265f0&color=fff&size=256&font-size=0.5&bold=true&name=${name}`,
        };

        await firebase.database().ref("users").child(userUid).set(userObject);
      }

      setBtnLoading(false);
      if (username) {
        props.history.push(`/${username}`);
      } else {
        props.history.push("/dashboard/orders");
      }
    } catch (error) {
      switch (error.code) {
        case "auth/weak-password":
          setError("The password must be at least 6 characters long.");
          break;
        case "auth/email-already-in-use":
          setError("The email address is already registered.");
          break;
        default:
          setError("Bad email or password, please retry!");
      }
    }
    setBtnLoading(false);
  };
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-sm mx-auto text-left pb-12 md:pb-20">
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Create your account 🤗
                </h2>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <RadioGroup value={selectedRole} onChange={setSelectedRole}>
                  <RadioGroup.Label className="text-lg font-medium text-gray-900">
                    Choose your role 👇
                  </RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    {signupRoles.map((role) => (
                      <RadioGroup.Option
                        key={role.id}
                        value={role.id}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active ? "ring-2 ring-indigo-500" : "",
                            "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <div className="flex-1 flex">
                              <div className="flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {role.title}
                                </RadioGroup.Label>
                              </div>
                            </div>
                            {checked ? (
                              <CheckCircleIcon
                                className="h-5 w-5 text-indigo-600"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-indigo-500"
                                  : "border-transparent",
                                "absolute -inset-px rounded-lg pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>

                {selectedRole === null && (
                  <div className="pt-5 mt-6 border-t border-gray-200">
                    <div className="text-sm">
                      Have an account?{" "}
                      <Link
                        className="font-medium text-indigo-500 hover:text-indigo-600"
                        to={`/signin${username ? "?username=" + username : ""}`}
                      >
                        Sign In
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {selectedRole === "influencer" && (
                <div className="max-w-sm mx-auto mt-6">
                  <a
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    href="https://app.channel.so/signup"
                  >
                    Go to creator portal
                  </a>
                </div>
              )}
              {selectedRole === "sponsor" && (
                <div className="max-w-sm mx-auto mt-6">
                  {/* <div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">
                        Sign up with
                      </p>

                      <div className="mt-1 grid grid-cols-3 gap-3">
                        <div>
                          <button
                            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            onClick={() => onSocialAuth("google")}
                          >
                            <span className="sr-only">Sign up with Google</span>
                            <img className="h-5 w-5" src={GoogleLogo} />
                          </button>
                        </div>

                        <div>
                          <button
                            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            onClick={() => onSocialAuth("facebook")}
                          >
                            <span className="sr-only">
                              Sign up with Facebook
                            </span>
                            <img className="h-5 w-5" src={FacebookLogo} />
                          </button>
                        </div>

                        <div>
                          <button
                            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            onClick={() => onSocialAuth("twitter")}
                          >
                            <span className="sr-only">
                              Sign up with Twitter
                            </span>
                            <img className="h-5" src={TwitterLogo} />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">
                          Or continue with
                        </span>
                      </div>
                    </div>
                  </div> */}

                  <div className="mt-6">
                    <div className="space-y-6">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your name
                        </label>
                        <div className="mt-1">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div>
                        <button
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={onSignup}
                        >
                          {btnLoading ? (
                            <PulseLoader
                              sizeUnit={"px"}
                              size={8}
                              color={"#fff"}
                            />
                          ) : (
                            "Create your account"
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="pt-5 mt-6 border-t border-gray-200">
                      <div className="text-sm">
                        Have an account?{" "}
                        <Link
                          className="font-medium text-indigo-500 hover:text-indigo-600"
                          to={`/signin${
                            username ? "?username=" + username : ""
                          }`}
                        >
                          Sign In
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default withRouter(SignUp);
