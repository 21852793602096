/* This example requires Tailwind CSS v2.0+ */
import React, { useState, Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import FastAverageColor from "fast-average-color";
import MessagesBody from "./MessagesBody";
import MessagesFooter from "./MessagesFooter";

export default function SingleOrderSlide({
  user,
  order,
  openSingleOrderSlide,
  setOpenSingleOrderSlide,
}) {
  const [bannerBgColor, setBannerBgColor] = useState("#fff");
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
  const [rejectIntent, setRejectIntent] = useState(false);

  const statusColor = (status) => {
    switch (status) {
      case "approved":
        return "bg-green-100 text-green-600";
      case "rejected":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-500";
    }
  };

  const typeIcon = (type) => {
    switch (type) {
      case "long-term":
        return (
          <svg
            className="w-4 h-4 fill-current text-green-500 flex-shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
          </svg>
        );
      default:
        return (
          <svg
            className="w-4 h-4 fill-current text-yellow-500 flex-shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M11.4 0L10 1.4l2 2H8.4c-2.8 0-5 2.2-5 5V12l-2-2L0 11.4l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.7-3.7L7.4 10l-2 2V8.4c0-1.7 1.3-3 3-3H12l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4L11.4 0z" />
          </svg>
        );
    }
  };

  const getProfileBannerColor = () => {
    const fac = new FastAverageColor();
    const container = document.querySelector(".profile-banner-container");
    const color = fac.getColor(container.querySelector("img"));

    setBannerBgColor(color.hex);
  };

  if (!order) {
    return null;
  }

  return (
    <Transition.Root show={openSingleOrderSlide} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-40"
        onClose={setOpenSingleOrderSlide}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full h-screen flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl h-screen">
                <div className="h-full flex-grow flex flex-col bg-white shadow-xl border-l border-gray-200 overflow-y-scroll">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {order.sponsorship.name}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          onClick={() => setOpenSingleOrderSlide(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div className="divide-y divide-gray-200">
                    <div className="pb-6">
                      <div
                        className="h-24 sm:h-20 lg:h-28"
                        style={{ backgroundColor: `${bannerBgColor}` }}
                      />
                      <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15">
                        <div>
                          <div className="-m-1 flex">
                            <div className="inline-flex rounded-lg overflow-hidden border-4 border-white profile-banner-container">
                              <img
                                className="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48 object-cover"
                                src={order.influencer.avatar}
                                alt=""
                                crossOrigin="anonymous"
                                onLoad={getProfileBannerColor}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 sm:ml-6 sm:flex-1">
                          <div>
                            <p className="text-sm text-gray-500">Offered by</p>
                            <div className="flex items-center">
                              <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                                {order.influencer.name}
                              </h3>
                            </div>
                          </div>
                          <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                            <p className="font-semibold py-0.5">Status:</p>{" "}
                            <div
                              className={`inline-flex font-medium rounded-full text-center ml-2 px-2.5 py-0.5 ${statusColor(
                                order.status
                              )}`}
                            >
                              {order.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-0">
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Sponsorship option
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                            <p>{order.sponsorship.name}</p>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Duration
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                            <div className="flex items-center">
                              {typeIcon(order.sponsorship.type)}
                              <div>
                                {order.sponsorship.type}{" "}
                                {order.sponsorship.type === "long-term" &&
                                  (order.sponsorship.duration > 1
                                    ? `(${order.sponsorship.duration} months)`
                                    : "(1 month)")}
                              </div>
                            </div>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Price
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                            <p>${order.sponsorship.price}</p>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Order placed on
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                            <time dateTime="1982-06-23">
                              {format(new Date(order.createdAt), "PP")}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    {/* Comments*/}
                    <div className="flex-1 flex flex-col h-full">
                      <MessagesBody user={user} order={order} />
                      <MessagesFooter user={user} order={order} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
