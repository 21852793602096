import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./StripeForm.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "40px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StripeForm = () => {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
};

export default StripeForm;
