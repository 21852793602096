import React from "react";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";

function OrdersTableItem(props) {
  const {
    openSingleOrderSlide,
    setOpenSingleOrderSlide,
    user,
    order,
    setOrder,
  } = props;

  const statusColor = (status) => {
    switch (status) {
      case "approved":
        return "bg-green-100 text-green-600";
      case "rejected":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-500";
    }
  };

  const typeIcon = (type) => {
    switch (type) {
      case "long-term":
        return (
          <svg
            className="w-4 h-4 fill-current text-green-500 flex-shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
          </svg>
        );
      default:
        return (
          <svg
            className="w-4 h-4 fill-current text-yellow-500 flex-shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M11.4 0L10 1.4l2 2H8.4c-2.8 0-5 2.2-5 5V12l-2-2L0 11.4l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.7-3.7L7.4 10l-2 2V8.4c0-1.7 1.3-3 3-3H12l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4L11.4 0z" />
          </svg>
        );
    }
  };

  const onSelectOrder = async () => {
    if (openSingleOrderSlide) {
      setOrder(null);
    } else {
      setOrder(order);
    }
    setOpenSingleOrderSlide(!openSingleOrderSlide);

    // set unreadFromInfluencer to 0 in sponsor-orders
    await firebase
      .database()
      .ref("sponsor-orders")
      .child(`${user.uid}/${order.id}`)
      .update({ unreadFromInfluencer: null });
  };

  if (!order) return;

  return (
    <tbody
      className={`text-sm hover:bg-gray-50 cursor-pointer`}
      onClick={onSelectOrder}
    >
      {/* Row */}
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center text-gray-800">
            <div className="font-medium text-light-blue-500">
              {order.sponsorship.name}
            </div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{format(new Date(order.createdAt), "PP")}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-gray-800">
            {order.influencer.name}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center">
            {typeIcon(order.sponsorship.type)}
            <div>
              {order.sponsorship.type === "one-time"
                ? "One-time"
                : order.sponsorship.duration > 1
                ? `${order.sponsorship.duration} months`
                : "1 month"}
            </div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left font-medium text-green-500">
            ${order.sponsorship.price}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(
              order.status
            )}`}
          >
            {order.status}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex justify-center text-gray-800">
            <div className="text-xs inline-flex  font-medium bg-indigo-400 text-white rounded-full text-center leading-5 px-2">
              {order.unreadFromInfluencer}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default OrdersTableItem;
