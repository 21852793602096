import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import PulseLoader from "react-spinners/PulseLoader";
import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";

function AccountPanel(props) {
  const { user } = props;
  const [name, setName] = useState(user.name || "");
  const [newAvatarFile, setNewAvatarFile] = useState(null);
  const [newAvatarURL, setNewAvatarURL] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const onSave = async () => {
    if (name === "") {
      toast.dark("😕 Your name can't be empty.", {
        autoClose: 4000,
      });
      return;
    }

    setBtnLoading(true);

    // update avatar
    if (newAvatarFile) {
      const avatarStorageRef = await firebase
        .storage()
        .ref("profile-photo")
        .child(user.uid)
        .child(newAvatarFile.name);
      const uploadedAvatar = await avatarStorageRef.put(newAvatarFile);
      const avatarDownloadURL = await uploadedAvatar.ref.getDownloadURL();

      await firebase.database().ref("users").child(user.uid).update({
        avatar: avatarDownloadURL,
      });
    }

    // update the name
    await firebase.database().ref("users").child(user.uid).update({
      name: name,
    });

    toast.dark("🥳 Your profile is updated.", {
      autoClose: 4000,
    });

    setBtnLoading(false);
  };

  return (
    <div className="flex-grow">
      {/* Panel body */}

      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">My Account</h2>
        {/* Name */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-5">
            Your name
          </h2>
          <div className="flex flex-wrap">
            <div className="mt-1 flex rounded-md">
              <input
                id="name"
                name="name"
                type="text"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </section>
        {/* Profile photo */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">
            Your profile photo
          </h2>
          <div className="text-sm">We recommend a 256px by 256px image</div>
          <div className="flex items-center mt-5">
            <div className="mr-4">
              <img
                className="w-20 h-20 object-cover rounded-full"
                src={newAvatarURL || user.avatar}
                width="80"
                height="80"
                alt="User upload"
              />
            </div>
            <span className="ml-5 rounded-md shadow-sm">
              <input
                type="file"
                accept="image/*"
                name="newAvatarURL"
                id="newAvatarURL"
                className="imageFileInput"
                onChange={(e) => {
                  setNewAvatarFile(e.target.files[0]);
                  setNewAvatarURL(
                    window.URL.createObjectURL(e.target.files[0])
                  );
                }}
                onClick={(e) => (e.target.value = "")}
              />
              <label
                htmlFor="newAvatarURL"
                className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-600  dark:text-white hover:text-gray-800 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out cursor-pointer"
              >
                Change
              </label>
            </span>
            {newAvatarURL && (
              <span
                className="ml-2 rounded-md shadow-sm cursor-pointer"
                onClick={() => {
                  setNewAvatarFile(null);
                  setNewAvatarURL(window.URL.revokeObjectURL(null));
                }}
              >
                <label className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </label>
              </span>
            )}
          </div>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-48"
              onClick={onSave}
            >
              {btnLoading ? (
                <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AccountPanel;
