import React, { useState } from "react";
import { Popup } from "react-typeform-embed";
import Modal from "../utils/Modal";
import { CheckCircleIcon } from "@heroicons/react/solid";

const includedFeatures = [
  "Offer one-time sponsorships",
  "Offer long-term sponsorships",
  "Spammy message protection",
  "Manage all sponsors in one place",
];

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-8 md:pb-12">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Bridge the gap between{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-400 to-indigo-500">
                creators
              </span>{" "}
              and{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-indigo-500">
                sponsors
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Channel makes it easy for creators like you to monetize from
                sponsorships for your creative work!
              </p>
              <div
                className="mt-6 my-8"
                data-aos="zoom-y-out"
                data-aos-delay="200"
              >
                <a
                  href="/signup"
                  className="px-5 rounded-md py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                >
                  👉 Get started for free
                </a>
              </div>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                {/* <div>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-600 sm:text-sm">
                      channel.so/
                    </span>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none border border-r-0 focus:outline-none sm:text-sm border-gray-300"
                      placeholder="jack"
                    />
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-gray-800 text-sm font-medium text-white hover:bg-gray-900 focus:z-10 focus:outline-none "
                    >
                      Claim Username
                    </button>
                  </div>
                </div> */}
                <ul
                  role="list"
                  className="mt-1 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4"
                >
                  {includedFeatures.map((feature) => (
                    <li
                      key={feature}
                      className="flex items-start lg:col-span-1"
                    >
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto h-auto shadow-sm rounded-md"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/channelso.appspot.com/o/assets%2Fhero-screenshot.png?alt=media&token=48d131c2-9464-44b1-8231-68109a953aea"
                  }
                  width="1000"
                  alt="Hero"
                />
              </div>
              {/* <button
                className="absolute top-full flex items-center transform -translate-y-1/2 text-white bg-gray-800 hover:bg-gray-900 rounded-full font-medium group p-4 shadow-lg"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVideoModalOpen(true);
                }}
                aria-controls="modal"
              >
                <svg
                  className="w-6 h-6 fill-current text-gray-100 group-hover:text-white flex-shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Watch the full video (2 min)</span>
              </button> */}
            </div>

            {/* Modal */}
            <Modal
              id="modal"
              ariaLabel="modal-headline"
              show={videoModalOpen}
              handleClose={() => setVideoModalOpen(false)}
            >
              <div className="relative pb-9/16">
                <iframe
                  className="absolute w-full h-full"
                  src="https://player.vimeo.com/video/174002812"
                  title="Video"
                  allowFullScreen
                ></iframe>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
